import { TextField, BooleanField, FunctionField, ReferenceField, BooleanInput, TextInput } from 'react-admin';
import FieldInput from './FieldInput';
import { FieldDisplay, FieldRecordDisplay } from './FieldDisplay';


export const formatSearchObject = (module: string, searchText: string) => {
	if(!searchText){
		return;
	}
	let srch: any = {};
	srch['cblistsearch_'+module] = searchText;
	return srch;
}

export const FormattedBooleanField = (props: any) => {
	if(props.record){
		props.record[props.source] = (Number(props.record[props.source]) === 1);
	}
	return (<BooleanField {...props} />);
};

export const FormattedBooleanInput = (props: any) => {
	if(props.record){
		props.record[props.source] = (Number(props.record[props.source]) === 1);
	}
	return (<BooleanInput {...props} />);
};

const getRefLink = (handleClickFun: any, refLink: any) => {
	if(handleClickFun) {
		return undefined;
	}

	return refLink || 'show';
}

export const getCustomCoreBOSInput = (resource: string, field: any = {}) => {
	const newInput: any = field;
	if(resource === 'cbVacationRequest' && (field.name === 'manager_user' || field.name === 'assigned_user_id')){
		newInput['displaytype'] = '4';
		return newInput;
	}

	return newInput;
}

export const RefModField = ({refMod, describe, field, handleClick, referenceCustomLink} : {refMod: string, describe: any, field: any, handleClick: any, referenceCustomLink: any}) => {

	if(Array.isArray(refMod)){
		return (
			<>
			{
				refMod.map((referenceMod) => {

					let eidfield = describe[referenceMod]?.labelFields?.split(',');

					if(!eidfield) return <></>;

					return (
						<div key={field.name+'_'+referenceMod}>
							<ReferenceField key={field.name} label={field.label} source={field.name} reference={referenceMod} link={() => getRefLink(handleClick, referenceCustomLink)} sortBy={referenceMod+'.'+eidfield[0]} >
								{ !handleClick ? 
									<FunctionField key={'ref'+field.name} label={field.label} render={(aRecord: any) => {
										if(eidfield.length > 1){
											return (
												<><TextField key={'ref'+field.name} source={eidfield[0]} onClick={ () => handleClick(aRecord, referenceMod, field)} color='primary' sx={{cursor: 'pointer'}} /> <TextField key={'ref'+field.name} source={eidfield[1]} onClick={ () => handleClick(aRecord, referenceMod, field)} color='primary' sx={{cursor: 'pointer'}} /></>
											)
										}

										return (
											<TextField key={'ref'+field.name} source={eidfield[0]} onClick={ () => handleClick(aRecord, referenceMod, field)} color='primary' sx={{cursor: 'pointer'}} />
										)}
									} />
									: <>
										{ eidfield.length > 1 ? <><TextField key={'ref'+field.name} source={eidfield[0]} /> <TextField key={'ref'+field.name} source={eidfield[0]} /></> : <TextField key={'ref'+field.name} source={eidfield[0]} /> }
									</>
								} 
							</ReferenceField>
						</div>
					);
				})
			}
			</>
		)
	}
	if(!describe[refMod]){
		return <></>;
	}
	let eidfield = describe[refMod].labelFields.split(',');
	if(!eidfield){
		return <></>;
	}

	return <ReferenceField key={field.name} label={field.label} source={field.name} reference={refMod} link={() => getRefLink(handleClick, referenceCustomLink)} sortBy={refMod+'.'+eidfield[0]} >
			{ handleClick ? 
				<FunctionField key={'ref'+field.name} label={field.label} render={(aRecord: any) => {
					if(eidfield.length > 1){
						return (
							<><TextField key={'ref'+field.name} source={eidfield[0]} onClick={ () => handleClick(aRecord, refMod, field)} color='primary' sx={{cursor: 'pointer'}} /> <TextField key={'ref'+field.name} source={eidfield[1]} onClick={ () => handleClick(aRecord, refMod, field)} color='primary' sx={{cursor: 'pointer'}} /></>
						)
					}
					return (
						<TextField key={'ref'+field.name} source={eidfield[0]} onClick={ () => handleClick(aRecord, refMod, field)} color='primary' sx={{cursor: 'pointer'}} />
					)}
				} />
				: <>{eidfield.length > 1 ? <><TextField key={'ref'+field.name} source={eidfield[0]} />  <TextField key={'ref'+field.name} source={eidfield[1]} /> </> : <TextField key={'ref'+field.name} source={eidfield[0]} /> }</>
			} 
		</ReferenceField>;
}

export const RefModRecordField = ({refMod, describe, field, handleClick, referenceCustomLink, record} : {refMod: string, describe: any, field: any, handleClick: any, referenceCustomLink: any, record?: any}) => {

	if(Array.isArray(refMod)){
		return (
			<>
			{
				refMod.map((referenceMod) => {
					let eidfield = describe[referenceMod]?.labelFields?.split(',');
					if(!eidfield) return <></>;


					return (
						<div key={field.name+'_'+referenceMod}>
							<ReferenceField key={field.name} label={field.label} source={field.name} record={record} reference={refMod} link={() => getRefLink(handleClick, referenceCustomLink)} sortBy={refMod+'.'+eidfield[0]} >
								{ handleClick ? 
									<FunctionField key={'ref'+field.name} label={field.label} render={(aRecord: any) => {
										if(eidfield.length > 1){
											return (
												<><TextField key={'ref'+field.name} source={eidfield[0]} onClick={ () => handleClick(aRecord, referenceMod, field)} color='primary' sx={{cursor: 'pointer'}} /> <TextField key={'ref'+field.name} source={eidfield[1]} onClick={ () => handleClick(aRecord, referenceMod, field)} color='primary' sx={{cursor: 'pointer'}} /></>
											)
										}
										return (
											<TextField key={'ref'+field.name} source={eidfield[0]} onClick={ () => handleClick(aRecord, referenceMod, field)} color='primary' sx={{cursor: 'pointer'}} />
										)}
									} />
									: <>{eidfield.length > 1 ? <><TextField key={'ref'+field.name} source={eidfield[0]} />  <TextField key={'ref'+field.name} source={eidfield[0]} /> </> : <TextField key={'ref'+field.name} source={eidfield[0]} /> }</>
								} 
							</ReferenceField>
						</div>
					);
				})
			}
			</>
		)
	}
	if(!describe[refMod]){
		return <></>;
	}
	let eidfield = describe[refMod].labelFields.split(',');
	return <ReferenceField key={field.name} label={field.label} source={field.name} record={record} reference={refMod} link={() => getRefLink(handleClick, referenceCustomLink)} sortBy={refMod+'.'+eidfield[0]} >
			{ handleClick ? 
				<FunctionField key={'ref'+field.name} label={field.label} render={(aRecord: any) => {
					if(eidfield.length > 1){
						return (
							<><TextField key={'ref'+field.name} source={eidfield[0]} onClick={ () => handleClick(aRecord, module, field)} color='primary' sx={{cursor: 'pointer'}} />  <TextField key={'ref'+field.name} source={eidfield[1]} onClick={ () => handleClick(aRecord, module, field)} color='primary' sx={{cursor: 'pointer'}} /> </>
						)
					}
					return (
						<TextField key={'ref'+field.name} source={eidfield[0]} onClick={ () => handleClick(aRecord, module, field)} color='primary' sx={{cursor: 'pointer'}} /> 
					)}
				} />
				: <>{eidfield.length > 1 ? <><TextField key={'ref'+field.name} source={eidfield[0]} />  <TextField key={'ref'+field.name} source={eidfield[0]} /> </> : <TextField key={'ref'+field.name} source={eidfield[0]} /> }</>
			} 
		</ReferenceField>;
}

export const getContactAsRefOptionText = (choice: any) => `${choice?.firstname} ${choice?.lastname}`;

const corebosUtils = {
	field2DisplayRecord: (field: any, record: any, module: string, describe: any = null, referToMod: string = '', referenceCustomLink ='', handleClick?: any) => {
		return <FieldRecordDisplay field={field} module={module} record={record} describe={describe} referToMod={referToMod} referenceCustomLink={referenceCustomLink} handleClick={handleClick} />
	},
	field2DisplayElement: (field: any, module: string, describe: any = null, referToMod = '', referenceCustomLink='', handleClick?: any) => {
		return <FieldDisplay field={field} module={module} describe={describe} referToMod={referToMod} referenceCustomLink={referenceCustomLink} handleClick={handleClick} />
	},
	field2InputElement: (field: any, module: string, styles: any = {}, describe: any = null, autogeneratedPlaceholder='', referToMod='', inputProps?: any, record = {}, customValidation = {}) => {
		if (field.uitype === '4') {
			return (
				<TextInput
					variant="outlined"
					key={field.name}
					label={field.label}
					source={field.name}
					readOnly
					value={autogeneratedPlaceholder}
					fullWidth={styles?.fullWidth}
				/>
			);
		}
		if (field.displaytype === '4') {
			return null;
		}
		return <FieldInput field={field} module={module} styles={styles} describe={describe} autogeneratedPlaceholder={autogeneratedPlaceholder} referToMod={referToMod} inputProps={inputProps} record={record} customValidation={customValidation} />
	},
	compare: (comparator: string, sourceValue: any, value: any) => {
		let conditionResp: any = '';
		switch (comparator) {
			case 'e':
				conditionResp += sourceValue === value;
				break;
			case 'n':
				conditionResp += sourceValue !== value;
				break;
			case 's':
				conditionResp += sourceValue.startsWith(value);
				break;
			case 'Ns':
				conditionResp += !sourceValue.startsWith(value);
				break;
			case 'ew':
				conditionResp += sourceValue.endsWith(value);
				break;
			case 'New':
				conditionResp += !sourceValue.endsWith(value);
				break;
			case 'c':
				conditionResp += sourceValue.indexOf(value) !== -1;
				break;
			case 'k':
				conditionResp += sourceValue.indexOf(value) === -1;
				break;
			case 'l':
				conditionResp += parseInt(sourceValue) < parseInt(value);
				break;
			case 'g':
				conditionResp += parseInt(sourceValue) > parseInt(value);
				break;
			case 'm':
				conditionResp += parseInt(sourceValue) <= parseInt(value);
				break;
			case 'h':
				conditionResp += parseInt(sourceValue) >= parseInt(value);
				break;
			default:
				conditionResp += false;
				break;
		}
		return conditionResp;
	},
};

export default corebosUtils;
