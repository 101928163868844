import { AutocompleteInput, DateInput, DateTimeInput, ImageField, ImageInput, NumberInput, ReferenceInput, SelectInput, TextInput, required } from "react-admin";
import { dateParser, dateTimeParser } from "../../utils/Helpers";
import { FormattedBooleanInput, formatSearchObject, getContactAsRefOptionText } from "./corebosUtils";



const RefInputField = ({referToMod, describe = {}, field, styles, isMandatory, isDisabled } : {referToMod: string, describe: any, field: any, styles: any, isMandatory: any, isDisabled?: boolean}) => {

    let refMod = '';
    if (referToMod) {
        refMod = referToMod;
    } else if (field.type.refersTo && Array.isArray(field.type.refersTo) && field.type.refersTo.length > 1) {
        refMod = field.type.refersTo;
    } else {
        refMod = field.type.refersTo[0] ?? '';
    }

    if (Array.isArray(refMod)) {
        return (
            <>
                {
                    refMod.map((referenceMod) => {
                        let eidfield = describe[referenceMod]?.labelFields?.split(',') ?? '';
                        if (!eidfield) return <></>;

                        if (eidfield.length > 1 && referenceMod === 'Contacts') {
                            return (
                                <div key={field.name + '_' + referenceMod}>
                                    <ReferenceInput variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label + ' - ' + referenceMod} source={field.name} reference={referenceMod} filterToQuery={(searchText: string) => formatSearchObject(referenceMod, searchText)} defaultValue={field?.default} >
                                        <AutocompleteInput disabled={isDisabled} key={'ref' + field.name} variant="outlined" optionText={getContactAsRefOptionText} validate={isMandatory} label={field.label + ' - ' + referenceMod} helperText={field.helpinfo} defaultValue={field?.default} />
                                    </ReferenceInput>
                                </div>
                            )
                        }

                        return (
                            <div key={field.name + '_' + referenceMod}>
                                <ReferenceInput variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label + ' - ' + referenceMod} source={field.name} reference={referenceMod} filterToQuery={(searchText: string) => formatSearchObject(referenceMod, searchText)} defaultValue={field?.default} >
                                    <AutocompleteInput disabled={isDisabled} key={'ref' + field.name} variant="outlined" optionText={eidfield[0]} label={field.label + ' - ' + referenceMod} helperText={field.helpinfo} defaultValue={field?.default} />
                                </ReferenceInput>
                            </div>
                        );
                    })
                }
            </>
        )
    }

    let eidfield = describe[refMod]?.labelFields?.split(',') ?? '';
    if (eidfield.length > 1 && refMod === 'Contacts') {
        return <ReferenceInput variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label} source={field.name} reference={refMod} filterToQuery={(searchText: string) => formatSearchObject(refMod, searchText)} defaultValue={field?.default}  >
            <AutocompleteInput key={'ref' + field.name} disabled={isDisabled} variant="outlined" label={field.label} optionText={getContactAsRefOptionText} validate={isMandatory} helperText={field.helpinfo} defaultValue={field?.default} />
        </ReferenceInput>;
    }

    return <ReferenceInput variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label} source={field.name} reference={refMod} filterToQuery={(searchText: string) => formatSearchObject(refMod, searchText)} defaultValue={field?.default}  >
        <AutocompleteInput key={'ref' + field.name} disabled={isDisabled} variant="outlined" label={field.label} optionText={eidfield[0]} validate={isMandatory} helperText={field.helpinfo} defaultValue={field?.default} />
    </ReferenceInput>;

}

const FieldInput = ({ field, module, styles = {}, describe = null, autogeneratedPlaceholder = '', referToMod = '', inputProps = {}, record = {}, customValidation = {} }: { field: any, module: string, styles?: any, describe?: any, autogeneratedPlaceholder?: string, referToMod?: string, inputProps?: any, record?: any, customValidation?: any }) => {

    let userlist = [];
    const isDisabled: boolean = inputProps && inputProps.hasOwnProperty('disabled') ? inputProps.disabled : !field?.editable;
    const helperText = inputProps?.helperText ?? ''
    const isMandatory: any = field.mandatory ? required() : null;
    const { min, max } = customValidation;
    switch (Number(field.uitype)) {
        case 21: // TextBox small
            return <TextInput disabled={isDisabled} variant="outlined" key={field.name} label={field.label} fullWidth={styles?.fullWidth} source={field.name} validate={isMandatory} defaultValue={field?.default} helperText={field.helpinfo} />;
        case 5: // Date
            return <DateInput disabled={isDisabled} parse={dateParser} variant="outlined" key={field.name} fullWidth={styles?.fullWidth} label={field.label} source={field.name} validate={isMandatory} helperText={field.helpinfo} />;
        case 50: // DateTime
            return <DateTimeInput disabled={isDisabled} parse={dateTimeParser} variant="outlined" key={field.name} fullWidth={styles?.fullWidth} label={field.label} source={field.name} validate={isMandatory} helperText={field.helpinfo} />;
        case 7: // Number
        case 9: // Percentage
        case 71: // Currency
            return <NumberInput disabled={isDisabled} variant="outlined" key={field.name} label={field.label} fullWidth={styles?.fullWidth} source={field.name} validate={isMandatory} max={max} min={min} helperText={helperText} />;
        case 10: { // Module Relation
            return <RefInputField referToMod={referToMod} describe={describe} field={field} styles={styles} isMandatory={isMandatory} />
        }
        case 52: // User Relation: Created and Modified by
        case 70: // Created and Modified Time
            return null;
        case 53: // User Relation: Assigned To
            userlist = describe[module]?.userlist ?? [];
            return <AutocompleteInput disabled={isDisabled} defaultValue={field?.default} variant="outlined" key={field.name} fullWidth={styles?.fullWidth} label={field.label} source={field.name} choices={userlist} optionText="username" optionValue="userid" helperText={field.helpinfo} />;
        case 101: // User Relation
            userlist = describe[module]?.userlist ?? [];
            return <SelectInput disabled={isDisabled} variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label} source={field.name} choices={userlist} optionText="username" optionValue="userid" defaultValue={field?.default} helperText={field.helpinfo} />;
        case 56: // Checkbox
            return <FormattedBooleanInput disabled={isDisabled} variant="outlined" key={field.name} label={field.label} source={field.name} record={record} defaultValue={field?.default}  />;
        case 28: // image
        case 69: // Image
            return <ImageInput key={field.name} label={field.label} source={field.name} accept={{ 'image/*': ['.png', '.jpg', 'jpeg', '.gif'] }} placeholder={<p>Drop your file here to upload or click to select</p>} >
                    <>
                        <ImageField key={'ref' + field.name} source="src" title={field.label} />
                        {record?._downloadurl && <ImageField key={'ref' + field.name+record.id} record={record} source="_downloadurl" title={record.title} />}
                    </>
            </ImageInput>;
        case 77:
        case 15: // SelectWithRole,
        case 16:  // Select,
        case 1613: // SelectModules,
        case 1024: // SelectRoles,
            return <SelectInput disabled = {isDisabled} variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label} source={field.name} choices={field.type.picklistValues} optionText="label" optionValue="value" validate={isMandatory} defaultValue={field?.default} helperText={field.helpinfo} />;

        default:
            return <TextInput disabled={isDisabled} variant="outlined" fullWidth={styles?.fullWidth} key={field.name} label={field.label} source={field.name} validate={isMandatory} defaultValue={field?.default} helperText={field.helpinfo} />;
    }

}

export default FieldInput;