import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PFPushAlong } from './PFPushAlong';
import { BUSINESS_PROCESS_FLOW } from '../constant';
import { MarkDownInput } from './MarkDown';
import { FormDataConsumer, useRecordContext, TabbedForm, FormTab, Toolbar, SaveButton, DeleteButton, useNotify, useUpdate, TabbedFormTabs, useTranslate, useRedirect, Notification } from "react-admin";
import { filterDependencyMaps, handleSpecialChars, uploadFile } from "../utils/Helpers";
import { doInvoke, remoteValidate } from '../utils/lib';
import cbUtils, { getCustomCoreBOSInput } from './corebosUtils/corebosUtils';
import { ParentDependencyField } from './ParentDependencyField';
import { DependentField } from './DependentField';
import { SaveImageHandler } from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';



const CustomToolbar = (props: any) => {

	const navigate = useNavigate();
	const { record, resource, modPermission, isLoading } = props;

	return (
		<Toolbar {...props} sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
			<div>
				{modPermission?.update && 
					<SaveButton
						label="Save"
						icon = { isLoading ? <CircularProgress color={isLoading ? 'primary' : 'secondary' } size={24} /> : <></>}
						disabled={isLoading}
						size="small"
						alwaysEnable={true}
						{...props}
					/>
				}
				<Button onClick={() => navigate(-1)} variant="contained" size='small' color="inherit" sx={{marginLeft: 2}}> Cancel </Button>
			</div>
			<>
			{modPermission?.delete && 
				<DeleteButton
					record={record}
					resource={resource}
					size="small"
				/>
			}	
			</>
			
		</Toolbar>
	);
}

const EditForm = ({ blocks, describe, resource, modPermission, ...props}: any) => {
	const record = useRecordContext(props);
	const [isLoading, setIsLoading] = useState(false);
	const notify = useNotify();
	const [dependentFields, setDependentFields] = useState<any[]>([]);
    const [dependentFieldsNames, setDependentFieldsNames] = useState<any[]>([]);
	const [ update ] = useUpdate();
	const { id } = useParams<any>();
	const [uploadedFileList, setUploadedFileList] = useState<any[]>([]);
	const [markdownInputValue, setMarkdownInputValue] = useState({});
	const translate = useTranslate();
    const redirect = useRedirect();



	const getProcessFlow = (field: any) => {
		const pf = BUSINESS_PROCESS_FLOW.find((element) => element.field === field);
		return pf;
	};
	const fetchMaps = useCallback(async () => {
        const module = resource + '_FieldDependencyPortal';

		let currentMap = await doInvoke('ProcessMap', {mapid: module}).catch((err: any) => {console.log(err);});

		if (currentMap && typeof currentMap !== "undefined") {
			const {allMaps, fieldNames} :{allMaps :any[], fieldNames :any[]} = filterDependencyMaps(currentMap);
			setDependentFields(allMaps);
			setDependentFieldsNames(fieldNames);
		}
      

    }, [resource]);


	useEffect(()=>{
		 fetchMaps();
	},[fetchMaps])


	const saveRecord = useCallback(
		async (values: any, fileList: any[], currentMarkdownInputValue: any) => {
			setIsLoading(true);
			const dataTosend: any = Object.assign(values, currentMarkdownInputValue);
			for (const key in dataTosend) {
				const value = dataTosend[key];
				if (value?.rawFile) {
					const result = await handleUpload(value.rawFile, '');
					dataTosend[key] = result[key] ?? result?._downloadurl;
				}else {
					dataTosend[key] = handleSpecialChars(dataTosend[key]);
				}
			}
			const validationRes: any = await remoteValidate(dataTosend.id, resource, dataTosend);
			if(validationRes.isValid){
				update(resource, { id: dataTosend.id, data: dataTosend, previousData: {} }, 
					{
						onSuccess: (result: any) => {
							const fileIds: any[] = [];
							fileList.forEach((uploadedDocument: any) => {
								fileIds.push(uploadedDocument.id);
							}); 
							if(fileIds.length > 0){
								doInvoke('SetRelation', { relate_this_id: result?.id, with_these_ids: JSON.stringify(fileIds)}, 'POST').then(() => {
									setUploadedFileList([]);
									setMarkdownInputValue('');
									redirect(`/${resource}/${result?.id}/show`)
								}).catch(() => {
									notify(`Upload: ${translate('Something went wrong')}`, {type: 'error'});
								}).finally(() => {
									setIsLoading(false);
								})
							} else {
								setUploadedFileList([]);
								setMarkdownInputValue('');
								redirect(`/${resource}/${result?.id}/show`)
							}
						},
						onError: (error: any) => {
							setIsLoading(false);
							notify(`Upload: ${translate('Something went wrong')}`, {type: 'error'});
						}
					}
				)  
			} else {
				setIsLoading(false);
				notify(validationRes?.errors[0]??'', {type: 'error'});
			}
		},
		[update, resource, redirect, notify, translate],
	);
	const saveThisImage: SaveImageHandler = async function*(data: ArrayBuffer, file: Blob) {
        const title = '';
        const result  = await handleUpload(file, title);
		if(result instanceof Error) {
            yield '';
            return true;
        }
        yield result?._downloadurl; 
        return !!result?._downloadurl;
    };

	const handleUpload = async (file: any, title: string) => {
		setIsLoading(true);
		const result  = await uploadFile(file, title, '', '', '');
        if(result instanceof Error) {
			setIsLoading(false);
			return null;
        }
		setUploadedFileList(uploadedFileList => [...uploadedFileList, result]);
		setIsLoading(false);
		return result;
	}

	const handleMarkdownField = (fieldName: string, value: any, cureentMarkdownInputValue: any) => {
		cureentMarkdownInputValue[fieldName] = value; 
		setMarkdownInputValue(cureentMarkdownInputValue);
	}

	return (
		<>
		<TabbedForm onSubmit={(values: any) => saveRecord(values, uploadedFileList, markdownInputValue)} toolbar={<CustomToolbar {...props} modPermission={modPermission} isLoading={isLoading} />} tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />}>
			{
				blocks.map((block: any, bidx: number) => {
					return (
						<FormTab key={'fbrefblk'+bidx} label={block.name}>
							<Box style={{width: '100%'}} py={3}>
								<Grid container spacing={2} >
									{
										block.fields.map((field: any) => {
											field = getCustomCoreBOSInput(resource, field);
											
											if(field.uitype === '19'){
												return (
													<Grid key={field.name} item xs={12} md={12}>
														<Box my={2}>
                                                            <Typography variant="subtitle2"> {field.label} </Typography>
															<MarkDownInput
																record={record}
																source={field.name}
																handleMarkdownField={handleMarkdownField}
																saveThisImage={saveThisImage}
																cureentMarkdownInputValue={markdownInputValue}
															/>
														</Box>
													</Grid>
												)
											}
											if(field.uitype === '10' && field.type?.refersTo && Array.isArray(field.type.refersTo) && field.type.refersTo.length > 1){
												return field.type.refersTo.map((referenceMod: string) => {
													if(describe[referenceMod]){
														return <Grid item xs={12} md={6} key={field.name +'_'+referenceMod}> {cbUtils.field2InputElement(field, resource, {fullWidth: true}, describe, '', referenceMod, null, record, {})} </Grid>
													}
													return <></>;
												});
											}
											const formattedDepenedncyMaps =  dependentFieldsNames.filter((e :any) => e?.dependentFieldName === field?.name);
											const formattedDepenedncyMapsForParent =  dependentFieldsNames.filter(e => e.parentFieldName === field?.name);											
											const cbField = cbUtils.field2InputElement(field, resource, {fullWidth: true}, describe, '', '', null, record, {});
											const processFlow = getProcessFlow(field.name);


											return cbField ? (
												<Grid key={field.name} item xs={12} md={6}>
													<FormDataConsumer>
														{({ formData }) => {

															if(processFlow){
																return (
																	<>
																		<Typography variant="subtitle2"> {field.label} </Typography>
																		<PFPushAlong
																			pfid={processFlow.pfId}
																			ctxid={id}
																			isDetailView={false}
																			graphId={`pfpaoutputgraph_${field.name}_${processFlow.pfId}`}
																		/>
																	</>
																)
															}

															if(formattedDepenedncyMaps.length > 0){
																return (
																	<DependentField
																		formattedDepenedncyMap={
																			formattedDepenedncyMaps
																		}
																		describe={describe}
																		resource={resource}
																		field={field}
																		source={field.name}
																		formValues={formData}
																		dependencyMaps={dependentFields}
																	/>
																)
															}

															if(formattedDepenedncyMapsForParent.length > 0){
																return (
																	<ParentDependencyField
																		formattedDepenedncyMap={
																			formattedDepenedncyMapsForParent
																		}
																		input={cbField}
																		field={field}
																		dependencyMaps={dependentFields}
																	/>
																)
															}


															return cbField
														}
														}
													</FormDataConsumer>
												</Grid>
											) : (
												<></>
											);
										})
									}
								</Grid>
							</Box>
						</FormTab>
					)
				})
			}
		</TabbedForm>
		<Notification />
		</>
	)
}

export default EditForm;